.icon-arrows {
  width: 30px;
  height: 30px;
  color: white;
}

.dots_indicators {
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
}

.dots_indicators li {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  margin: 0 3px;
  padding: 0;
}

.dots_indicators li button {
  border: none;
  padding: 0;
  margin: 0;
  background: #DADADA;
  color: transparent;
  cursor: pointer;
  height: 8px;
  width: 8px;
  border-radius: 10px;
}

.dots_indicators li.slick-active button {
  background-color: #0D6EFD;
}

.custom_arrows_sliders {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  width: 40px;
  height: 40px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50px);

  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 50%;
  padding: 5px;
  /* border: 1px solid black; */
  cursor: pointer;
}
.custom_arrows_sliders.prev {
  left: 10px;
}
.custom_arrows_sliders.next {
  right: 10px;
}

@media only screen and (min-width: 992px) {
  .icon-arrows {
    width: 40px;
    height: 40px;
  }
}