.custom-dropdown:focus-visible {
  outline: none;
}

.custom-dropdown > div {
  border: 1px solid #aaa;
  padding: 0.1rem 0;
  border-radius: 8px;
}

.custom-dropdown > div:hover {
  border-color: #aaa;
  box-shadow: none;
}