@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

.card-itineray--container {
  overflow: hidden;
  padding-right: 1rem;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  border-radius: 18px;

  ._ {}

  .innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .image-section {
    width: 42%;
    flex-shrink: 0;
    overflow: hidden;
    border: 0px;
    border-radius: 18px;
    position: relative;
  }
  
  .card-body {
    width: 70%;
    overflow: hidden;
    margin: 0.7rem 0;
  }

  .user-profile--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    cursor: pointer;

    ._ {}

    img {
      width: 20px;
      height: 20px;
      border-radius: 9999px;
    }
  }
  
  p .card-title .cart-text {
    font-family: "DM Sans", sans-serif;
  }
  img {
    aspect-ratio: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-title {
    font-size: 13px;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .card-text {
    font-size: 11px;
    
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-text.date {
    font-size: 11px;
    font-weight: bolder;
  }

  .flag-country {
    padding: 0.2rem 0.5rem;
    font-weight: 500;
    font-size: 0.8em;
    color: white;
    width: fit-content;
    border-radius: 18px;
  }

  .range-date {
    font-size: 0.9em;
    font-weight: 600;
  }

  .content {
    font-size: 0.9em;
    display: block;
    width: 80px; 
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
}

.card-content-table {
  border-collapse: collapse;
  width: 100%;

  ._ {}

  td {
    font-size: 0.8em;
    padding: 0;
    vertical-align: top;

    &:last-child {
      padding-left: 0.5rem;
    }
  }
}


@media only screen and (min-width: 400px) {
  .card-itineray--container {
    
    ._ {}

    .image-section {
      width: 37%;
    }

    .content {
      width: 120px;
    }
  }
}

@media only screen and (min-width: 450px) {
  .card-itineray--container {

    ._ {}

    .flag-country {
      font-size: 0.9em;
    }

    .image-section {
      width: 28%;
    }
    .card-body {
      width: 72%;
    }

    .content {
      width: 160px;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) {
  .card-itineray--container {

    .card-title {
      font-size: 13px;
    }
    .card-text {
      font-size: 12px;
    }

    .image-section {
      width: 29%;
    }

    .card-body {
      width: 71%;
    }

    .content {
      width: 250px; 
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-itineray--container {

    .card-title {
      font-size: 20px;
      margin-top: -3px;
    }
    .card-text {
      font-size: 14px;
    }
    
    /* .image-section {
      width: 29%;
    } */
  
    .card-body {
      /* width: 71%; */
      padding: 0 0 0.5rem;
    }
  }
}

@media only screen and (min-width: 992px) {}
