.custom-table-day-view-container {
  width: 100%;
  margin-top: 2rem;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.btn-back {
  display: none;
}

.btn-back svg {
  width: 30px;
  height: 30px;
}

.react-big-calendar-custom {
  font-size: 0.9em;
  text-align: start;
  width: 800px;
}

.react-big-calendar-custom .rbc-time-view {
  padding: 1rem 0.5rem;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.5), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.react-big-calendar-custom .rbc-time-header {
  display: none;
}

.react-big-calendar-custom .rbc-time-content{
  border-top: none;
  overflow-x: scroll;
}

.react-big-calendar-custom .rbc-timeslot-group {
  text-align: start;
}

.react-big-calendar-custom .rbc-event-label {
  display: none;
}

.react-big-calendar-custom .rbc-event-content {
 font-size: 0.8em;
 display: flex;
}

.custom-event-activity-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.custom-event-activity-container h5 {
  font-size: 13px;
  font: 600;
  padding: 0;
  margin: 0;
}

.custom-event-activity-container span {
  font-size: 10px;
}

.category-event-activity {
  padding: 4px;
  border-radius: 4px;
  font-size: 8px;
  background-color: white;
  color: black;
  display: none;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

@media (min-width: 992px) {
  .custom-table-day-view-container {
    overflow: hidden;
    padding-bottom: 4.3rem;

    overflow-y: hidden;
    overflow-x: unset;
  }
  
  .react-big-calendar-custom {
    width: unset;
  }

  .btn-back {
    display: flex;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 9999px;
    
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    
    width: fit-content;
    height: fit-content;
  }
}