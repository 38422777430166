:root {
  --primary-color: #0D6EFD;
  --secondary-color: #fff;
  --disabled-bg: rgba(239, 239, 239, 0.3);
  --disabled-color: rgb(84, 84, 84);
  --background-color: #EEEEEE
}

.pagination-custom {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.pagination-custom li > a {
  background-color: var(--background-color);
  color: var(--primary-color) !important;
  border: 1px solid transparent;

  margin: 0 5px;
  padding: 10px 12px;
  
  border-radius: 999px;
  transition: background-color 0.3s, color 0.3s;

  min-width: 32px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  line-height: 1;

  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.pagination-custom a:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color) !important;
}

.pagination-custom .active a {
  background-color: var(--primary-color);
  color: var(--secondary-color) !important;
}

.pagination-custom .disabled a {
  background-color: var(--disabled-bg);
  color: var(--disabled-color) !important;

  cursor: not-allowed;
  pointer-events: none;
}

/* Optional: Add some additional styling for previous and next buttons */
.pagination-custom .previous li > a, .pagination-custom .next li > a {
  color: var(--primary-color);
}


.pagination-custom .previous a:hover, .pagination-custom .next a:hover {
  color: var(--secondary-color);
}

@media screen and (min-width: 992px) {
  .pagination-custom {
    margin-top: 20px;
  }

  .pagination-custom li > a {
    padding: 10px;
  }

}