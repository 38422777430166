
.sub-extension-information--container {
  margin-left: 1.5rem;
}

.sub-extension-content--parent {
  display: flex;
  flex-direction: column;
}

.sub-extension-content--wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}
.sub-extension-content--wrapper:last-child {
  border-bottom: none;
  padding-top: 5px;
  padding-bottom: 0;
}

.sub-extension-content--wrapper {
  display: none;
  margin-left: 1.4rem;
}
.sub-extension-content--wrapper.visible {

  display: block;
}

.sub-extension-content--container {
  font-size: 0.8em;

  .extension-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
    width: 70%;
  }
  .full {
    flex: 1;
  }
}

.attachment-link--color {
  color: #0d6efd !important;
}

.attachment-link--color:hover {
  color: red !important;
  text-decoration: underline;
}