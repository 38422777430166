.card-article-image--container {
  /* width: 250px; move to inline style */
  /* height: 300px; move to inline style */
  display: flex;

  color: white;

  overflow: hidden;
  /* border-radius: 12px; move to inline style */

  padding: 0.5rem;

  cursor: pointer;
}

.card-article-image-content {
  padding: 0.6rem 1.2rem;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);

  width: 100%;

  align-self: self-end;
}

.card-article-image--title {
  font-size: 1em;
  margin: 0 0 5px;
  padding: 0;
}

.text-one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.card-article-image--content-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.8em;
  }
}