.upload-form--container {
  width: 100%;
  height: 200px;

  border: 2px dotted #aaa;
  padding: 1rem;
  position: relative;
  
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  cursor: pointer;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;

    opacity: 0;
  }
}

.upload-form--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: relative;
  overflow: hidden;
}

.label {
  font-size: 1em;
  font-weight: 500;

  span {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
}