:root {
  --green: #2f6b38;
  --red: #771c37;
  --blue: #0a28ae;
  --orange: #a45c12;
  --green-opacity: rgba(47, 107, 56, 0.3);
  --red-opacity: rgba(119, 28, 55, 0.3);
  --blue-opacity: rgba(10, 40, 174, 0.3);
  --orange-opacity: rgba(164, 92, 18, 0.3);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card {
  margin-bottom: 20px;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
  border: none;
}

.border-none {
  border: none;
}

.bg-red {
  color: rgb(225, 83, 83);
}
.bg-blue {
  color: rgb(66, 87, 239);
}

.bg-white {
  color: white;
}

.border-bottom-blue {
  border-bottom: 2px #0d6efd solid;
}

.text-blue {
  color: #0d6efd !important;
}

.inner {
  overflow: hidden;
  border-radius: 5%;
}
