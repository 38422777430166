.card-itineraries--container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem;
  
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.card-itineraries--title-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  position: relative;

  ._ {}

  .thumbnail-section {
    width: 50px;
    height: 50px;

    border-radius: 9999px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

  }

  .title-section {
    display: flex;
    flex-direction: column;
    gap: 0;

    .title-itinearies {
      position: relative;
      
      h1 {
        font-size: 1em;
        font-weight: 600;
        margin: 0;
        padding: 0;
        
        cursor: pointer;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 150px;
      }

      .title-tooltip {
        position: absolute;
        z-index: 1000;
        top: 20px;

        background-color: black;
        /* border: 1px solid white; */
        padding: 0.2rem 0.5rem;

        color: white;
        font-size: 0.9em;
        white-space: nowrap;

        visibility: hidden;
      }

      &:hover > .title-tooltip {
        animation: 0s linear 1s forwards delayedShow;
      }
    }

    .sub-title-itineraries {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      margin-top: 5px;

      .user-photo-profile {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 9999px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bold {
        font-size: 0.9em;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.card-itineraries-detail--container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .itineraries-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    font-size: 0.9em;

    .title {
      font-size: 0.9em;
      font-weight: 600;
    }
  }
}

.card-example-1 {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 16px;
  overflow: hidden;

  cursor: pointer;
  padding-bottom: 3rem;
}

.card-img--container {
  height: 200px;
  margin: 3px;
  border-radius: 16px;
  overflow: hidden;

  position: relative;

  ._ {}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .trips {
    background-color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 12px;
    font-size: 0.9em;
    font-weight: 600;
    
    position: absolute;
    top: 10px;
    right: 10px;

    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.card-content--container {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;

  ._ {}

  .title-container {
    position: relative;

    .title-text {
      font-size: 1.3em;
      font-weight: 600;
      
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 90%;

      margin: 0;
      padding: 0;
    }
    
    .title-tooltip {
      position: absolute;
      z-index: 1000;
      top: 25px;

      background-color: black;
      /* border: 1px solid white; */
      padding: 0.2rem 0.5rem;

      color: white;
      font-size: 0.9em;
      white-space: nowrap;

      visibility: hidden;
    }

    &:hover > .title-tooltip {
      animation: 0s linear 1s forwards delayedShow;
    }
  }

  .destination-text {
    font-size: 0.9em;
    font-weight: 600;
    color: #6CACB4;
  }
}

@keyframes delayedShow {
  to {
    visibility: visible;
  }
}