@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

*{
  font-family: "DM Sans", sans-serif;
}
h3{
  font-weight: 600;
}

:root {
  --green: rgb(47, 107, 56);
  --red: rgb(119, 28, 55);
  --blue: rgb(10, 40, 174);
  --orange: rgb(164, 92, 18);
  --green-opacity: rgba(47, 107, 56, 0.3);
  --red-opacity: rgba(119, 28, 55, 0.3);
  --blue-opacity: rgba(10, 40, 174, 0.3);
  --orange-opacity: rgba(164, 92, 18, 0.3);
}

a:-webkit-any-link{
  text-decoration:none;
  color:#1c1c1c;
}

.notification.dropdown-toggle::after {
  content: none;
}
ul li a.nav-link {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  color: black;
}

ul li a.nav-link:hover {
  color: darkgreen;
}

.navbar-toggler {
  /* background: black; */
  /* border: 3px double; */
  padding: 10px 6px;
  outline: none;
}

.navbar-toggler:focus,
.navbar-toggler:focus,
.navbar-toggler-icon:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

.navbar-toggler span {
  display: block;
  width: 22px;
  height: 2px;
  background: black;
  margin-top: 4px;
}

/* .navbar-expand-lg .navbar-nav .nav-link:hover {
  border-bottom: 4px solid black;
} */

.navbar-expand-lg .navbar-nav .nav-link:active {
  border-bottom: 4px solid black;
  color: darkgreen;
}

/* jumbotron */
.jumbotron {
  /* background-image: url(https://source.unsplash.com/400x300?landscape); */
  background-repeat: no-repeat;
  background-size: cover;  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 300px;
}

.jumbotron .display-4 {
  color: white;
  text-align: center;
  padding-top: 140px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  font-size: calc(0.8rem + 2.7vw);
}

.jumbotron .lead {
  color: white;
  text-align: center;
  font-weight: 300;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* info panel */
.info-panel {
  background-color: white;
  border-radius: 12px;
  margin-top: -40px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  /* margin-right: 20px; */
}
/* .section-category {
  padding-top: -30px;
} */

/* title header */
.section-header h2 {
  font-family: "DM Sans", sans-serif;
  font-size: 2em;
}

#EmptyComponent {
  width: 100% !important;
  height: 100% !important;
}

.text-semi-transparent {
  color: rgba(0, 0, 0, 0.6);
}

/* footer */
#footer a {
  text-decoration: none;
  color: black;
  
}
#footer{
  margin-bottom: -15px;
  padding-bottom: 0;
}

#poster,
#city-card,
#article,
#hotel,
#footer {
  margin-top: 80px;
}

/* dropdown sort by */
/* .dropdown {
  background-color: rgb(18, 115, 199) o !important;
  color: black !important;
  transition: 0.2s ease;
} */

/* filter interest */
.country a {
  text-decoration: none;
  color: rgb(95, 95, 95);
}

.country a:hover {
  color: rgb(0, 194, 191);
}

/* scroller */
.media-scroller {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  margin: 0 -12px;
}

.media-scroller > div > div:first-child {
  margin-left: 12px;
}

.media-scroller > div > div:last-child {
  margin-right: 12px;
}

.media-scroller::-webkit-scrollbar {
  width: 20px;
  height: 7px;
  border-radius: 20px;
}

.media-scroller::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 20px;
  margin: 0 400px;
}

.media-scroller::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#007bff, #4285f4);
  border-radius: 20px;
}

.media-scroller::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 20px;
  margin: 0 400px 0;
}

.media-scroller::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#007bff);
  border-radius: 20px;
}

/* horizontal scrollbar */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
  height: 5px;
}

::-webkit-scrollbar-track {
  /* background: rgba(251, 248, 248, 0.1); */
  background: rgba(251, 248, 248, 0.1);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 20px;
}

#inspiring .section-header {
  margin-top: 100px;
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style-type: none;
  display: flex;
  margin-left: -30px;
  margin-top: 60px;
}
.paginationBttns a {
  padding-right: 10px;
  margin-right: 8px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.paginationBttns a:hover {
  /* color: #ffffff; */
  background-color: #d1dbff;
}

.paginationActive a {
  color: #050a98;
}

.scroll {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.scroll-homepage {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  margin: 0 -24px;
}
.scroll-homepage::-webkit-scrollbar {
  display: none;
}

.scroll-content {
  justify-content: start;
}

.category {
  min-width: 6em;
  height: 6em;
  margin-right: 15px;
  border: 0px;
  border-radius: 10px;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: #2d2c2c;
}

.category:first-child {
  margin-left: 24px;
}
.category:last-child {
  margin-right: 24px;
}

.category h5 {
  font-size: 0.9rem;
  color: #ffffff;
  text-shadow: 1px 1px 1px #5b5b5b;
  position: absolute;
  text-align: center;
}
.category img {
  height: 100%;
}

.info-panel .option {
  font-family: "DM Sans", sans-serif;
}

h2.section-title {
  font-family: "DM Sans", sans-serif;
  font-size: 26px;
}
.show-all-btn.sub-article {
  /* margin-top: 4em;
  margin-left: -20px; */
  align-self: center;
}
.show-all-btn.main-article {
  /* margin-top: 7em;
  margin-left: -2em; */
  align-self: center;
}
.show-all-btn > a {
  color: #000000;
}
.show-all-btn:hover {
  color: #007bff;
}
.show-all-btn > a:hover {
  color: #007bff;
}
.show-all-btn .rounded-circle {
  font-family: "DM Sans", sans-serif;
  width: 35px;
  height: 35px;
  /* font-size: 20px;
  text-align: center; */
  line-height: 39px;
  border: 1.8px solid #000000;

  display: flex;
  justify-content: center;
  align-items: center;
}
.show-all-btn > p {
  font-size: 12px;
  display: none;
}
.show-all-btn:hover .rounded-circle {
  border: 1.8px solid #007bff;
}
/* .navbar-collapse{
  overflow-y: auto;
} */

.select-option {
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 12px;
  color: black;
  padding: 5px 8px;
  background-color: #E9E9E9;
  cursor: pointer;
  scroll-behavior: smooth;
  font-size: 10px;

  appearance: none;
  background-image: url('../assets/images/bottomArrow.svg');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
}

.fade-in {
  border-radius: 12px;
  animation: fadeIn 2s ease-in;
}

.fade-out {
  border-radius: 12px;
  animation: fadeOut 2s ease-out;
}

@keyframes fadeIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.2);
  }
  to {
    background-color: transparent;
  }
}

.link-focus {
  text-decoration: none;
  color: black;
}
.link-focus:focus {
  color: #DADADA;
}
.link-focus:active {
  color: #DADADA;
}

/* style page itinerary **/
.custom-calendar-contaier {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option-view {
  list-style: none;
  display: inline-flex;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.option-view-item {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1.2em;
}

.option-view-item:hover {
  background-color: #212529;
  color: white;
}

.option-view-item.active {
  background-color: #212529;
  color: white;
}

.content-calendar {
  display: none;
}

.content-calendar.active {
  display: flex;
}

.content-calendar-month-view {
  justify-content: center;
  margin-bottom: 2rem;
}

.content-calendar-week-view {
  justify-content: center;
  width: max-content;
  overflow: hidden;
  margin-bottom: 2rem;
}

.content-calendar-day-view {
  width: 100%;
}

.title {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.btn-add {
  width: 30px;
  height: 30px;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

.form-input-itinerary {
  padding: 0 0.5rem;
}

.form-input-itinerary, .form-input-trip, .form-input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input-group {
  gap: 5px;

  &.w-full {
    width: 100%;
  }
}

.form-input-group .form-input {
  padding: 0.5rem 1rem;

  border: 1px solid #aaa;
  border-radius: 8px;
}

.form-date-input-group {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.form-input-detail-trip-container {
  display: none;
  margin-top: 0.5rem;
}

.form-input-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-input-detail-trip {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.form-input-detail-trip form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-top: 1px solid #aaa;
  padding-top: 1rem;
}

.list-detail-trip {
  display: flex;
  flex-direction: column;

  padding: 1rem 1.5rem;
  border-radius: 14px;
  
  width: 100%;
  gap: 10px;
  position: relative;
}

.list-detail-trip:first-child {
  margin-top: 1rem
}

.list-detail-trip .title {
  font-size: 1.2em;
  font-weight: 600;
}

.list-detail-trip .detail-trip {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.list-detail-trip .detail-trip .sub-detail-trip {
  display: inline-flex;
  gap: 5px;

  font-size: 0.8em;
}

.list-detail-trip .detail-trip .sub-detail-trip span {
  padding: 0.2rem 0.5rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.list-detail-trip .detail-trip .time {
  font-size: 0.8em;
  font-weight: 400;
}

.list-detail-trip .icon-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;

  width: 30px;
  height: 30px;

  background: transparent;
  border: 0;
  padding: 0;
}
/* end style page itinerary **/

.itinerary-list--container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 1rem;
}

@media only screen and (min-width: 576px) {
  .media-scroller {
    margin: 0 -2.5rem;
  }
  .media-scroller > div > div:first-child {
    margin-left: 2.5rem;
  }
  .media-scroller > div > div:last-child {
    margin-right: 2.5rem;
  }

  .scroll-homepage {
    margin: 0 -2.5rem;
  }
  .category:first-child {
    margin-left: 2.5rem;
  }
  .category:last-child {
    margin-right: 2.5rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .info-panel .option {
    font-size: 12px;
  }
  .info-panel .list-option {
    font-size: 8px;
  }
  .media-scroller {
    grid-auto-columns: 55%;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .info-panel .option {
    font-size: 11px;
  }
  h2.section-title {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
  }
  .jumbotron .display-4 {
    padding-top: 150px;
    font-size: 2.5rem;
  }
  .show-all-btn .rounded-circle {
    width: 40px;
    height: 40px;
    /* font-size: 20px; */
    line-height: 38px;
  }
  .show-all-btn > p {
    font-size: 16px;
  }
  .show-all-btn.sub-article {
    margin-top: 6em;
  }
  .show-all-btn.main-article {
    margin-top: 8.5em;
  }

  .media-scroller {
    margin: 0;
  }
  .media-scroller > div > div:first-child {
    margin-left: 0;
  }
  .media-scroller > div > div:last-child {
    margin-right: 0;
  }

  .scroll-homepage {
    margin: 0;
  }
  .category:first-child {
    margin-left: 0;
  }
  .category:last-child {
    margin-right: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .select-option {
    padding: 5px 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .category {
    width: 8em;
    height: 8em;
  }
  .category h5 {
    font-size: 1.4rem;
  }
  .category img {
    width: 8em;
    height: 8em;
  }
  h2.section-title {
    font-family: "DM Sans", sans-serif;
    font-size: 32px;
  }
  .paginationBttns a {
    font-size: 20px;
  }
  section {
    margin-top: 15px;
  }
  .jumbotron .display-4 {
    padding-top: 145px;
    font-size: 2.8rem;
  }
  .show-all-btn .rounded-circle {
    width: 45px;
    height: 45px;
    /* font-size: 20px; */
    line-height: 43px;
  }
  .show-all-btn > p {
    font-size: 16px;
  }
  .show-all-btn.sub-article {
    margin-top: 6.1em;
  }
  .show-all-btn.main-article {
    margin-top: 9em;
  }
  .scroll-content {
    justify-content: center;
  }
  .content-month-view {
    margin-bottom: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
