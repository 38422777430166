.trip-form--container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.data-extension--title {
  font-size: 1.3em;
  font-weight: 400;
  
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  span {
    font-weight: 600;
  }

  &.no-border {
    border-bottom: none;
  }

  &.no-margin {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.form-input--wrapper {
  display: flex;
  gap: 10px;
}

.color-list--container {
  display: flex;
  gap: 10px;
}

.color--item {
  width: 35px;
  height: 35px;
  border-radius: 9999px;
  cursor: pointer;
}

.button-add-action--container {
  display: none;

  &.visible {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.form-input-extension--container {
  display: none;

  &.visible {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.form-extension--container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-height: 500px;
  overflow-y: scroll;

  &.summary-form {
    gap: 2rem;
    padding: 0.5rem 0;
  }
}

.toggle-switch--container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 1rem;
  padding: 1px;
  
  .label {
    font-size: 1em;
    font-weight: 600;
  }
}

.form-summary--container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-summary--header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 1.2em;
    font-weight: 600;
  }

}

.btn-action-summary {
  background-color: transparent;
  border: 1px solid #aaa;
  border-radius: 12px;
  font-size: 0.8em;
  padding: 2px 1.5rem;
}

.table-summary {
  margin: 0.5rem 3px 0;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.table-summary thead {
  font-size: 1em;
  border-bottom: 1px solid #aaa;
  background-color: #FBFCFE;
  padding: 0.5rem;
  overflow: hidden;
}


.table-summary th:first-child {
  border-top-left-radius: 4px;
}

.table-summary th:last-child {
  border-top-right-radius: 4px;
}

.table-summary tbody {
  font-size: 0.9em;
}

.table-summary th, td {
  padding: 0.5rem;
}


/* ===== Attachment Form ===== */

.attachment-upload-progress-bar--container {
  width: 100%;
  height: 30px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 10px 0;
}

.attachment-upload-progress-bar {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
  border-radius: 6px;

  span {
    margin: 2px 5px;
  }
}

.attachment-container--title {
  font-weight: 600;
  font-size: 1.2em;
  margin-top: 1rem;
}

.attachment-item--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0.5rem;
  align-items: center;
  overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 1rem 1.5rem;
}

.attachment-item {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .name {
    width: 75%; /* Atur lebar sesuai kebutuhan */
    white-space: nowrap; /* Agar teks tidak patah baris */
    overflow: hidden; /* Atur overflow agar teks yang melebihi lebar tersembunyi */
    text-overflow: ellipsis; /* Teks yang terlalu panjang akan diganti dengan titik-titik */
  }

  span > .icon {
    width: 20px;
    height: 20px;
    color: #DC3545;
    cursor: pointer;
  }
}

.attachment-link {
  color: #4286f4 !important;
  font-weight: 600;
}

.simple-select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
  
  
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
  outline-width: 0;
  padding-left: 1rem;
}

.mandatory-field-label {
  margin: 0;
  color: red;
  font-weight: 400;
}


.form-input-group > label {
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .trip-form--container {
    flex-direction: row;
  }

  .trip-form--parent {
    width: 40%;
  }

  .extension-form--parent {
    width: 60%;
  }
}