@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&family=Kanit&family=Press+Start+2P&display=swap");

.background-image-popup-campaign {
  overflow: hidden;
  position: relative;
  padding: 3px;
}

.content-popup-campaign {
  background-image: url('https://images.unsplash.com/photo-1682687220742-aba13b6e50ba?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  
  min-height: 400px;
  padding: 2rem;

  color: white;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
}

.close-button-popup-campaign {
  border: 0;
  background: transparent;

  position: absolute;
  top: 8px;
  right: 8px;
}

.icon-close-popup-campaign {
  width: 25px;
  height: 25px;
  color: white;
}

.btn-explore-popup-campaign {
  border: 1px solid black;
  background-color: black;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  color: white;
}

.btn-explore-popup-campaign:hover {
  background-color: white;
  color: black;
}