.rmdp-wrapper.custom-calendar {
  width: 40vmax !important;
  border-radius: 1rem;

  /* box-shadow: 0 0 0 transparent !important; */
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);
  align-self: center;
}

.custom-calendar .rmdp-calendar, .rmdp-day-picker, .rmdp-day-picker > div {
  width: 100%;
}

.custom-calendar .rmdp-header-values {
  font-size: 18px;
}

.custom-calendar .rmdp-arrow  {
  width: 10px;
  height: 10px;

  border: solid black;
  border-width: 0 2px 2px 0;
}

.custom-calendar .rmdp-arrow-container:hover {
  background-color: black;
}

.custom-calendar .rmdp-week-day {
  color: black;
}

.custom-calendar .rmdp-day-picker {
  margin-top: 1rem;
}

.custom-calendar .rmdp-day {
  width: 44px;
  height: 40px;
}

.custom-calendar .rmdp-day.rmdp-today span {
  background-color: white;
  color: black;
}

.custom-calendar .rmdp-range {
  background-color: transparent;
  box-shadow: none;
  color: #0074d9;
}

.custom-calendar .rmdp-day span {
  border-radius: 8px;
  font-size: 14px;
}

.custom-calendar .rmdp-range.start:not(.force) {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.custom-calendar .rmdp-range.end:not(.force) {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}


@media only screen and (min-width: 992px) {
  .rmdp-wrapper.custom-calendar {
    width: 100% !important;
    box-shadow: none;
  }
}