.mini-modal {
  min-height: 400px;
  max-height: 500px;
  overflow: hidden;
  /* overflow-y: scroll; */
}

.modal-general-content { 
  min-height: 700px;
  max-height: 800px;
}

.modal-general-content.w-full {
  min-height: 800px;
  max-height: 850px;
  margin: 0 auto;
}

.modal-general-content > .modal-body {
  overflow: auto;
}

.modal-general-content.w-full > .modal-body {
  overflow: scroll;
}

.no-padding {
  .modal-body { padding: 0; }
}


@media only screen and (min-width: 576px) {
  .w-full {
    width: 90%;
    max-width: none;
  }

  .modal-general-content.w-full > .modal-body {
    overflow: hidden;
  }
}