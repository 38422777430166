.calendar-month-big-container {
  display: none;
}

.calendar-big {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.calendar-big--header {
  margin: 1rem 0;
}

.calendar-big--header-title {
  text-align: center;
  font-weight: 600;
  background-color: white;
}

.calendar-big--days {
  background: #D7D7D7;
  grid-gap: 1px;
  padding: 1px;
}

.calendar-big--each-day {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}


.calendar-big--each-day:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.calendar-big--each-day:hover .calendar-big--day > .day-container > .day {
  /* color: white; */
}

.calendar-big--day {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7rem;
  height: 6.5rem;

  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
}

.day-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;
  height: 100%;
}

.trip-on-day {
  display: none;
  background: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.1);

  padding: 1px 0.5rem;
  max-width: 90%;
  min-width: 90%;
}

.event-sign {
  position: absolute;
  right: 5px;
  top: 5px;
  
  width: 15px;
  height: 15px;

  font-size: 10px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  border-radius: 9999px;
}

.event-each-day-title {
  font-size: 11px !important;
  font-weight: 600;
  max-width: 800px;
  height: fit-content;

  margin: 0;
  padding: 3px 1px;
  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-big--each-day:hover .trip-on-day {
  display: flex;
  justify-content: center;
  animation: slideUp 0.5s ease;
}

.trip-on-day:last-child {
  margin-top: 5px;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: 992px) {
  .calendar-month-big-container {
    display: flex;
    flex-direction: column;
  }
}