.card-popular-destination--container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-radius: 12px;
  overflow: hidden;

  height: 430px;
  max-height: 430px;

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  ._{}

  .image-section {
    height: 300px;

    ._ {}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.title-card {
  font-size: 1.2em;
  font-weight: 600;
  color: black;
  margin: 0;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
}

.link-active > a {
  color: black;
  font-size: 0.9em;
  font-weight: 500;

  &:hover {
    color: blue;
  }
}

.sub-content--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 1rem;

  flex: 1;
}

.sub-content-text {
  font-weight: 600;
  font-size: 1em;
}

.color-blue-old {
  color: #0D807E;
}

.color-gray {
  color: gray;
}