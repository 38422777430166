.card--img-section {
  width: 22%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  ._ {}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.card--content-section {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-top: 3px;
  width: 73%;

  ._ {}

  .name-messager {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    ._ {}

    .name {
      font-weight: 600;
      font-size: 1.1em;
      width: 50%;
    }

    .time {
      font-size: 0.85em;
    }
  }

  .content-message {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .message {
    width: 90%;
  
    font-size: 0.98em;
    
    margin: 0;
    padding: 0;
  }

  .new-messages {
    border-radius: 9999px;
    padding: 0.15rem 0.6rem;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 0.8em;
    background-color: #FF7C56;
  }
}

.text-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; 
}

.chat-list--container {
  height: 100%;
  
  display: flex;
  flex-direction: column;

  /* padding-bottom: 1rem; */
}

.user-receiver--container {
  display: flex;
  align-items: center;
}

.user-receiver--info {
  display: flex;
  align-items: center;

  padding: 1rem;
  /* background-color: peru; */
  flex: 1;

  border-left: 1px solid rgba(0, 0, 0, 0.1);

  gap: 8px;
  
  ._ {}

  .img {
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px;

    ._ {}

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 0;

    ._ {}

    .user-name--info {
      font-size: 1.1em;
      font-weight: 600;

      padding: 0;
      margin: 0;
    }

    .user-last-seen--info {
      padding: 0;
      margin: 0;

      font-size: 0.8em;
    }
  }
}

.input-chat--container {
  position: sticky;
  bottom: 0;

  display: flex;
  align-items: center;
  /* background-color: peru; */
  gap: 0.5rem;
  padding: 1rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  ._ {}

  textarea {
    flex: 1;
    resize: none;
    padding: 0.3rem 0.5rem;
    outline: none;
    font-size: 0.9em;
    border-radius: 4px;

    max-height: 110px;

    ._ {}

    &::-webkit-scrollbar { display: none; }
  }

  button {
    background-color: transparent;
    border: none;
    border-radius: 9999px;
  }
}

.chat-list--parent {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
}

.chat-list {
  --rad: 20px;
  --rad-sm: 3px;

  flex: 1;

  overflow-y: scroll;
  
  padding: 0.5rem 1rem;
  margin: auto;

  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: relative; */

  ._ {}

}

.date-group {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 0.5rem 1rem;

  font-size: 0.7em;

  margin: 0;
  width: fit-content;
  align-self: center;

  ._ {}

  &:first-child {
    margin-top: 1rem;
  }
}

.chat-bubble {
  background-color: bisque;

  position: relative;
  max-width: 75%;
  padding: 7px 15px;
  margin-bottom: 2px;
}

.chat-bubble.sent {
  border-radius: var(--rad) var(--rad-sm) var(--rad-sm) var(--rad);
  /* background: #42a5f5; */
  background: black;
  color: #fff;
  /* moves it to the right */
  margin-left: auto;
}

.chat-bubble.rcvd {
  border-radius: var(--rad-sm) var(--rad) var(--rad) var(--rad-sm);
  background: #f1f1f1;
  color: #555;
  /* moves it to the left */
  margin-right: auto;
}

.chat-bubble.sent:first-child,
.chat-bubble.rcvd+.chat-bubble.sent {
  border-top-right-radius: var(--rad);
}

.chat-bubble.rcvd:first-child,
.chat-bubble.sent+.chat-bubble.rcvd {
  border-top-left-radius: var(--rad);
}

/* time */

.msg::before {
  content: '14:00 WIB';
  font-size: 0.8rem;
  position: absolute;
  bottom: 100%;
  color: #888;
  white-space: nowrap;
  /* Hidden by default */
  display: none;
}

.msg.sent::before {
  right: 15px;
}

.msg.rcvd::before {
  left: 15px;
}


/* Show time only for first message in group */

.msg:first-child::before,
.msg.sent+.msg.rcvd::before,
.msg.rcvd+.msg.sent::before {
  /* Show only for first message in group */
  display: block;
}
