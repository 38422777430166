.itinerary-card {
  background: white;
  padding: 1rem 1.2rem;

  display: flex;
  flex-direction: column;
  width: 100%;
  
  border-radius: 12px;

  position: relative;
  
}

.itinerary-card::after {
  display: block;
  width: 0;
  content: "";
  border: 15px solid transparent;
  position: absolute;
  z-index: 0;
}

.itinerary-card.bubble-left::after {
  top: 12px;
  left: -15px; 
}

.itinerary-card.bubble-left::after {
  border-right-color: white;
  border-left: 0px;
}

.border-left-hider {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50px;
  width: 3px;
  z-index: 5;
  background: #EEEEEE;
}

.itinerary-card:last-child {
  .border-left-hider {
    display: block;
  }
}

.itinerary-card.no-data {
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  .border-left-hider {
    display: block;
  }
}

.icon-category--container {
  position: absolute;
  top: 0px;
  left: -72px;
  z-index: 10;

  width: 45px;
  height: 45px;
  background: white;

  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.icon-action--container {
  position: absolute;
  bottom: 50%;
  right: -20px;
  
  width: 40px;
  height: 40px;
  background: white;
  border: none;
  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.action--list--container {
  position: absolute;
  bottom: 40%;
  right: 30px;

  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  padding: 0.5rem 1rem;
  
  border-radius: 12px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.9em;

    li {
      cursor: pointer;
      margin: 0.5rem 0;

      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .icon-action {
    width: 50px;
    height: 50px;
  }
}

.itinerary-card--title-container {
  display: flex;
  justify-content: space-between;

  .itinerary-card--title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;

    .date-event {
      font-size: 0.8em;
      font-weight: 600;
      color: #5771a5;
    }

    .destination-event {
      margin-left: 0;

      h5 {
        font-size: 0.8em;
        span {
          color: #d7d8d8;
        }
      }
    }

    .duration-event {
      font-size: 0.8em;
      margin-left: 0;
      border-left: 1px solid #cfd0cf;
      border-right: 1px solid #cfd0cf;
      padding: 0 10px;
    }
  }

  .itinerary-card--budget {
    display: none;
  }
}

.itinerary-card--content-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-top: 1rem;
}

.itinerary-card--start-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.itinerary-card--icon {
  width: 50px;
  height: 50px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.itinerary-card--title-flight {
  h3 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  span {
    font-size: 0.9em;
    font-weight: 500;
    color: #cfd0cf;
  }
}

.itinerary-card--middle-container {
  flex: 1;

  .itinerary-card--route {
    display: flex;
    align-items: center;
    gap: 5px;

    .country-name {
      font-size: 1em;
      font-weight: 600;
    }
  }

  .itinerary-card--seat {
    font-size: 0.8em;
    font-weight: 500;
    color: #babcbb;
  }
}

.itinerary-card--end-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 0.9em;

  .info-text {
    font-weight: 400;
    color: #babcbb;
    
    display: flex;
    flex-direction: column;
  }

  .time-text {
    font-weight: 600;
    
    display: flex;
    flex-direction: column;
  }
}

.btn-more-information--container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  h5 {
    font-size: 0.8em;
  }
}

.btn-more-information--container.no-margin {
  margin: 0;
}

.btn-more-information {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;

  svg {
    width: 11px;
    height: 11px;
    transform: rotate(90deg);
    transition: transform 0.2s ease;
  }

  svg.opened {
    transform: rotate(180deg);
  }
}

.extension-information--container {
  display: none;
  transition: display 0.5s ease;
}
.extension-information--container.visible {
  display: flex;
  flex-direction: column;
}


@media only screen and (min-width: 566px) {
  .itinerary-card {
    padding: 1rem 2rem;
  }

  .itinerary-card--content-container {
    flex-direction: row;
    align-items: center;
  }

  .itinerary-card--title-container {
    .itinerary-card--title-wrapper {
      flex-direction: row;
      align-items: center;
      gap: 0;

      .date-event {
        font-size: 1em;
      }

      .destination-event {
        margin-left: 1.5rem;

        h5 {
          font-size: 1em;
        }
      }

      .duration-event {
        margin-left: 1rem;
        font-size: 1em;
      }
    }
  }

}

@media only screen and (min-width: 768px) {
  .itinerary-card--title-container {

    .itinerary-card--budget {
      display: block;
      color: #C8A4D4;

      h3 {
        font-size: 1.2em;
      }
    }
  }
}