.custom-week-view-container {
  display: flex;
  justify-content: center;
  gap: 8px;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  
  padding: 0.2rem 1rem;
  overflow: hidden;
  margin: 3px;

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.custom-week-view-item {
  border-radius: 16px;
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  transition: background-color 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  width: 5vmax;
  height: 8vmax;
}

.custom-week-view-item.active {
  background-color: #212529;
  color: white;
  font-size: 15px;
  font-weight: bold;
}