.datepicker-component--container {
  width: 100%;
}

.custom-input {
  border-radius: 8px;
  border: 1px solid #aaa;
  padding: 1rem 12px;
  height: 42px;
  width: 100%;
}

.datepicker-component--container .rmdp-container {
  width: 100%;
}