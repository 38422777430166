.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}

.strike>span {
  position: relative;
  display: inline-block;
}

.strike>span:before,
.strike>span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 999px;
  /* Here is the modification */
  border-top: 1px solid grey;
}

.strike>span:before {
  right: 100%;
  margin-right: 15px;
}

.strike>span:after {
  left: 100%;
  margin-left: 15px;
}

/* .modal-login {
  max-width: 380px;
} */
.modal-login .modal-header {
  font-size: 13px;
}

.form-check {
  margin-top: 15px;
  font-size: 14px;
}

.signup-section a {
  font-size: 14px;
  color: blue;
}