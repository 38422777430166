.main-img {
  overflow: hidden;
}

.inner {
  position: relative;
}

.inner img {
  transition: all 1.5s ease;
}

.inner:hover img {
  transform: scale(1.4);
}

.main-img {
  position: relative;
}

.wishlist {
  margin: 20px;
  position: absolute;
  right: -6px;
}

.wishlist p {
  color: white;
  margin-top: 160px;
  font-size: 20px;
}