.subData {
  padding: 5px 15px;
} 

.form-summary--container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-summary--header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 1.2em;
    font-weight: 600;
  }

  .btn-action-summary {
    background-color: transparent;
    border: 1px solid #aaa;
    border-radius: 12px;
    font-size: 0.8em;
    padding: 2px 1.5rem;
  }
}

.table-summary {
  margin: 0.5rem 3px 0;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
}

.table-summary thead {
  font-size: 1em;
  border-bottom: 1px solid #aaa;
  background-color: #FBFCFE;
  padding: 0.5rem;
  overflow: hidden;
}


.table-summary th:first-child {
  border-top-left-radius: 4px;
}

.table-summary th:last-child {
  border-top-right-radius: 4px;
}

.table-summary tbody {
  font-size: 0.9em;
}

.table-summary th, td {
  padding: 0.5rem;
}